<template>
  <div style="position:relative; height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>
    <v-container fluid>
<!--      <div class="notice-top_menu mt-1 mb-6">-->
<!--        <h3>{{ $t('notice.title') }}</h3>-->
<!--      </div>-->
      <NoticeList :notices="notices" />
    </v-container>
  </div>
</template>

<script>
import NoticeList from "@/modules/notice/components/NoticeList";

export default {
  components: {NoticeList},
  data() {
    return {
      preloader:true,
      notices: []
    }
  },
  created() {
    this.getAllNotices()
  },
  methods: {
    async getAllNotices() {
      let customers = this.$store.getters.customersCrm.data

      if (customers === undefined) {
        this.$store.dispatch('customersCrm').then(() => {
          customers = this.$store.getters.customersCrm.data
        })
      }

      await this.$store.dispatch('notices', 1).then(() => {
        this.notices = this.$store.getters.notices.map(notice => {
          let customer = customers.find(customer => customer.id === notice.customer_id)

          if (undefined !== customer) {
            notice.customer = customer.company_name
          }

          let employee = this.$store.getters.employeesCrm.data.find(employee => employee.id === notice.employee_id)

          if (undefined !== employee) {
            notice.employee = employee.first_name + ' ' + employee.last_name
          } else {
            notice.employee = 'Administrator'
          }

          return notice;
        })

        this.preloader = false
      })
    }
  }
}
</script>
